import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";

import styles from "./styles";
import { StaticQuery } from "gatsby";
import { withStyles } from "@material-ui/core/styles";
import HeaderSection from "../../components/HeaderSection";
import moment from "moment";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { graphql } from "gatsby";
import { Typography } from "@material-ui/core";
const ApplyForm = ({ location, submit, course, date, data, classes }) => {
  const { nextStarts } = data.allMarkdownRemark.edges[0].node.frontmatter;
  const dates = nextStarts;


  const [formState, setFormState] = useState({
    course,
    date,
    cablifecycle: "Lead",
    marketingfunnel: "undefined",
    firstname: null,
    lastname: null,
    email: null,
    phone: null,
    bildungsgutschein: null,
    city: "Berlin",
  });
  const [openAgreeError, setOpenAgreeError] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const handleAgree = () => {
    setIsAgreed(!isAgreed);
  };

  const handleChange = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      course,
      date,
      firstname,
      lastname,
      email,
      bildungsgutschein,
    } = formState;

    if (
      isAgreed &&
      course &&
      date &&
      firstname &&
      lastname &&
      email &&
      // phone &&
      bildungsgutschein
      // marketingfunnel
    ) {
      submit(formState);
    } else {
      if (!isAgreed) {
        setOpenAgreeError(true);
      }
    }

  };
  const handleClose = () => {
    setOpenAgreeError(false);
  };
  return (
    <form onSubmit={handleSubmit}>
      {/* <React.Fragment> */}
      <HeaderSection
        header="Complete the application"
        location={location}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={openAgreeError}
      >
        <DialogTitle id="simple-dialog-title">
          Please check the terms agreement to proceed with the application
        </DialogTitle>
      </Dialog>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <InputLabel id="course-select-label">
            Course
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="course-select-label"
            id="course-select"
            name="course"
            value={formState?.course ? formState.course : ""}
            onChange={handleChange}
          >
            <MenuItem value="web">
              Web Development
            </MenuItem>
            <MenuItem value="data">
              Data Science
            </MenuItem>
            <MenuItem value="unsure">
              Unsure
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel id="date">
            Desired start date*
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="date"
            id="date"
            name="date"
            value={formState?.date ? formState.date : ""}
            onChange={handleChange}
            disabled={formState?.course ? false : true}
          >
            {
              formState?.course === "unsure"
                ? [...new Set(dates.map((item) => item.date))].map((start) => {
                  return (
                    <MenuItem key={start} value={start}>
                      {moment(start).format("dddd, Do MMMM YYYY")}
                    </MenuItem>
                  );
                })
                :
                dates
                  .filter(course => new Date(course.date) > new Date())
                  .map(
                    (start) =>
                      formState?.course === start.course && (
                        <MenuItem key={start.date} value={start.date}>
                          {moment(start.date).format("dddd, Do MMMM YYYY")}
                        </MenuItem>
                      )
                  )


            }
            <MenuItem value="unsure">
              Unsure
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstname"
            label="Firstname"
            fullWidth
            autoComplete="name"
            value={formState?.firstname ? formState?.firstname : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastname"
            name="lastname"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            value={formState?.lastname ? formState?.lastname : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="email"
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            value={formState?.email ? formState?.email : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            // required
            type="tel"
            id="phone"
            name="phone"
            label="Phone number"
            fullWidth
            autoComplete="tel"
            value={formState?.phone ? formState?.phone : ""}
            onChange={handleChange}
          />
        </Grid>



        <Grid item xs={12} sm={6}>
          <InputLabel id="bildungsgutschein-label">
            Are you eligible for a Bildungsgutschein?*
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="bildungsgutschein-label"
            id="bildugsgutschein"
            name="bildungsgutschein"
            value={
              formState?.bildungsgutschein ? formState.bildungsgutschein : ""
            }
            onChange={handleChange}
            required
          >
            <MenuItem value="haveBildungsgutschein">
              I already have it
            </MenuItem>
            <MenuItem value="verbalApprovalBildungsgutschein">
              I have verbal approval for it
            </MenuItem>
            <MenuItem value="inProcessBildungsgutschein">
              I am in the process of applying for it
            </MenuItem>
            <MenuItem value="dontknowBildungsgutschein">
              I don't know and I would like to learn more
            </MenuItem>
            <MenuItem value="noBildungsgutschein">
              I am not eligible for it
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel id="marketing-funnel-label">
            Where did you hear about us?*
          </InputLabel>
          <Select
            className={classes.selectCourses}
            labelId="marketing-funnel-label"
            id="marketing-funnel"
            name="marketingfunnel"
            value={formState?.marketingfunnel ? formState.marketingfunnel : ""}
            onChange={handleChange}

          >
            <MenuItem value="personalReferral">
              Personal referral
            </MenuItem>
            <MenuItem value="google">
              Google
            </MenuItem>
            <MenuItem value="switchup">
              Switch-Up
            </MenuItem>
            <MenuItem value="coursereport">
              Course Report
            </MenuItem>
            <MenuItem value="kursenet">
              Kursenet
            </MenuItem>
            <MenuItem value="wdb">
              WDB
            </MenuItem>
            <MenuItem value="linkedin">
              LinkedIn
            </MenuItem>
            <MenuItem value="facebook">
              Facebook
            </MenuItem>
            <MenuItem value="instagram">
              Instagram
            </MenuItem>
            <MenuItem value="other">
              Other
            </MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            If you would like to to have an offer, Please make sure to enter the course and add your address and kundennummer to the message and we will send you an offer shortly.
          </Typography>
          <TextField
            id="msg"
            name="msg"
            label="Message"
            fullWidth
            multiline
            rows="4"
            value={formState?.msg ? formState?.msg : ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            value={formState.city === "Berlin" ? true : false}
            onChange={() => setFormState({ ...formState, city: formState.city === "Berlin" ? "" : "Berlin" })}
            control={<Checkbox defaultChecked color="secondary" name="berlin" />}
            label="I am currently residing in Berlin"
          />
        </Grid>
        {formState.city !== "Berlin" && <Grid item xs={12}>
          Please be aware that our courses are fully on-site in Berlin and we do not offer any remote courses. We do not provide any visa or accommodation support.
        </Grid>}
        <Grid item xs={12}>
          <FormControlLabel
            value={isAgreed}
            onChange={handleAgree}
            control={<Checkbox color="secondary" name="agree" />}
            label="I agree to terms and usage of my data. Code Academy Berlin will never sell or use these information for commercial purposes."
          />
        </Grid>
        <Grid item xs={12} className={classes.flexCentered}>
          <Button
            disabled={
              isAgreed &&
                formState.course &&
                formState.date &&
                formState.firstname &&
                formState.lastname &&
                formState.email &&
                // formState.phone &&
                // formState.marketingfunnel &&
                formState.bildungsgutschein
                ? false
                : true
            }
            type="submit"
            variant="outlined"
            className={
              isAgreed &&
                formState.course &&
                formState.date &&
                formState.firstname &&
                formState.lastname &&
                formState.email &&
                // formState.phone &&
                // formState.marketingfunnel &&
                formState.bildungsgutschein
                ? classes.button
                : classes.buttonDisabled
            }
            disableElevation
            color="primary"
          // onClick={handleSubmit}
          >
            Send Application
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const NextStartsQuery = ({ location, submit, course, date, classes }) => (
  <StaticQuery
    query={graphql`
      query NextStartsDateQuery {
        allMarkdownRemark(
          filter: { frontmatter: { key: { eq: "nextStarts" } } }
        ) {
          edges {
            node {
              frontmatter {
                nextStarts {
                  date
                  course
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => (
      <ApplyForm
        location={location}
        data={data}
        submit={submit}
        course={course}
        date={date}
        classes={classes}
        count={count}
      />
    )}
  />
);

export default withStyles(styles)(NextStartsQuery);
